<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">
      <div>
        <span>{{ $tc('label.pagamento', 1)}}</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <metadados-container-layout
        v-if="carregouMetadados"
        :metadados-entidade="metadadosInterno"
        :campos-formulario="camposFormulario"
        :ordenacao-campos="ordenacaoCampos"
        :layout-class="layoutClass"
        ignora-obrigatorios
        :input-layout="inputLayout"
        :somenteLeitura="true"
        :objeto="acaoFomatada"
        ref="container">
      </metadados-container-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { copyObject } from '../../../common/functions/helpers';
import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';

export default {
  props: {
    acao: Object,
    configuracao: Object,
    metadados: {
      type: Object,
      default: {},
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      camposFormulario: {},
      ordenacaoCampos: [],
      acaoFomatada: {},
      metadadosInterno: null,
      carregouMetadados: false,
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
    };
  },
  watch: {
    acao() {
      this.carregouMetadados = false;
      setTimeout(this.carregarComponente, 2E2);
    },
  },
  computed: {
    getCampos() {
      return this.configuracao.passo6.camposDinamicosPagamentoManual || [];
    },
    getOrdemCampos() {
      return this.configuracao.passo7.listaLiquidacaoManual || [];
    },
  },
  methods: {
    setMetadados() {
      this.metadadosInterno = copyObject(this.metadados);
      const camposDinamicos = {};
      if (this.metadadosInterno) {
        const campos = copyObject(this.metadadosInterno.mapaCamposDinamicos);
        if (this.getCampos) {
          this.getCampos.forEach((campo) => {
            if (campos[campo.label] && this.acaoFomatada[campo.label]) {
              camposDinamicos[campo.label] = campos[campo.label];
            }
          });
        }
        this.metadadosInterno.mapaCamposDinamicos = camposDinamicos;
        this.metadadosInterno.mapaEntidades = {};
      }
    },
    preencheCamposFomularios() {
      const campos = [];

      if (this.acaoFomatada.pendente_pagamento) {
        campos.push({
          labelCampo: 'pendente_pagamento',
          tipoCampo: 'DINHEIRO',
        });
      }
      if (this.acaoFomatada.total_pagamento) {
        campos.push({
          labelCampo: 'total_pagamento',
          tipoCampo: 'DINHEIRO',
        });
      }
      if (this.acaoFomatada.status_pagamento) {
        campos.push({
          labelCampo: 'status_pagamento',
          tipoCampo: 'LISTA',
          desAtributos: {
            textoItem: 'texto',
            valorItem: 'valor',
            lista: [
              {
                texto: this.$t('status_entidade_pagamento.aguardando_pagamento'),
                valor: 'AGUARDANDO_PAGAMENTO',
              },
              {
                texto: this.$tc('status_entidade_pagamento.aprovado', 1),
                valor: 'APROVADO',
              },
              {
                texto: this.$tc('status_entidade_pagamento.programado', 1),
                valor: 'PROGRAMADO',
              },
              {
                texto: this.$tc('status_entidade_pagamento.liquidado', 1),
                valor: 'LIQUIDADO',
              },
              {
                texto: this.$t('status_entidade_pagamento.bloqueado'),
                valor: 'BLOQUEADO',
              },
              {
                texto: this.$t('status_entidade_pagamento.cancelado'),
                valor: 'CANCELADO',
              },
            ],
          },
        });
      }
      if (this.acaoFomatada.evidencia) {
        campos.push({
          labelCampo: 'evidencia',
          tipoCampo: 'TEXTO',
        });
      }
      if (this.acaoFomatada.justificativa) {
        campos.push({
          labelCampo: 'justificativa',
          tipoCampo: 'TEXTO',
        });
      }
      if (this.acaoFomatada.data_liquidacao) {
        campos.push({
          labelCampo: 'data_liquidacao',
          tipoCampo: 'DATA',
        });
      }
      if (this.acaoFomatada.numero_documento) {
        campos.push({
          labelCampo: 'numero_documento',
          tipoCampo: 'TEXTO',
        });
      }
      this.camposFormulario.padrao = campos;
    },
    montarOrdenacao() {
      this.ordenacaoCampos = [];
      this.ordenacaoCampos.push('numero_documento');
      this.ordenacaoCampos.push(...this.getOrdemCampos
        .sort((a, b) => a.ordenacao < b.ordenacao)
        .map((c) => c.label));
      this.ordenacaoCampos.push('data_liquidacao');
      this.ordenacaoCampos.push('justificativa');
    },
    formatarObjeto() {
      const desAtributos = this.acao.desAtributosPagamento
        ? JSON.parse(this.acao.desAtributosPagamento.value) : {};

      const acaoFormatada = { ...this.acao, ...desAtributos };

      acaoFormatada.status_pagamento = acaoFormatada.statusPagamento;
      acaoFormatada.numero_documento = acaoFormatada.numeroDocumento;
      if (acaoFormatada.dataLiquidacao) {
        acaoFormatada.data_liquidacao = this.moment(acaoFormatada.dataLiquidacao, 'DD/MM/YYYY').format();
      }
      acaoFormatada.total_pagamento = acaoFormatada.totalPagamento;
      acaoFormatada.pendente_pagamento = acaoFormatada.pendentePagamento;

      this.acaoFomatada = acaoFormatada;
    },
    carregarComponente() {
      this.formatarObjeto();
      this.preencheCamposFomularios();
      this.setMetadados();
      this.montarOrdenacao();
      this.carregouMetadados = true;
    },
  },
  mounted() {
    this.carregarComponente();
  },
};
</script>
<style>
  .select--sem--linha > .v-input__control > .v-input__slot:before {
    width: 0% !important;
  }
  .select--sem--linha > .v-input__control > .v-input__slot:after {
    border-style: none;
  }
</style>
